import React from 'react';
import { Link } from 'gatsby';
import { Button } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { Seo } from '../../modules/common/components/seo';
import { SectionHeader } from '../../modules/common/components/section-header';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

const ToGTierListPage: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page characters-page-nikke tog-tier '}
      game="tog"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/tog/">Tower of God: New World</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier list</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/tog/categories/category_tier.png"
            alt="Tier"
          />
        </div>
        <div className="page-details">
          <h1>Tower of God: New World Tier list</h1>
          <h2>
            Tier list for Tower of God: New World that rates all characters.
          </h2>
          <p>
            Last updated: <strong>30/03/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      <div className="tier-list-page nikke">
        <SectionHeader title="Important Notice" />
        <p>
          Our Tower of God wiki <strong>will be removed soon</strong>. Sadly,
          the direction the game has taken (leaning too much toward Pay 2 Win)
          and the declining popularity of the game, forced us to make that
          decision. If you're looking for up to date information about the game,
          check{' '}
          <a
            href="https://www.youtube.com/@Conowen96"
            target="_blank"
            rel="noreferrer"
          >
            Conowen's
          </a>{' '}
          YouTube channel and the sheet he maintains (linked below).
        </p>
        <Button
          variant="primary"
          href="https://docs.google.com/spreadsheets/u/0/d/1paOxiwoKOzzR0E5W_r1SYLVuliJomnz8BkNQt8vNhQU/htmlview#"
          target="_blank"
          rel="noreferrer"
        >
          Conowen's ToG Sheet
        </Button>
      </div>
    </DashboardLayout>
  );
};

export default ToGTierListPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier list | Tower of God: New World | Prydwen Institute"
    description="Tier list for Tower of God: New World that rates all characters."
    game="tog"
  />
);
